import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faFacebook, faPatreon, faPaypal } from '@fortawesome/free-brands-svg-icons'

const getIcon = (type) => {
  switch (type) {
    case "instagram":
      return faInstagram
    case "facebook":
      return faFacebook
    case "patreon":
      return faPatreon
    case "paypal":
      return faPaypal
    default:
      return faLink
  }
}

const getHref = (type, value) => {
  switch (type) {
    case "instagram":
      return `https://instagram.com/${value}`
    case "facebook":
      return `https://facebook.com/${value}`
    case "patreon":
      return `https://patreon.com/${value}`
    default:
      return value
  }
}

const Social = ({ type, value }) => {
  return (
    <a href={getHref(type, value)} className="w-9 h-9 flex justify-center items-center bg-slate-200 rounded-full hover:bg-slate-300 text-slate-600">
      <FontAwesomeIcon icon={getIcon(type)} />
    </a>
  )
}

export default Social
