import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import { GiftIcon } from "@heroicons/react/24/solid"
import Layout from "../../components/layout"
import Social from "../../components/social"

const money = (value, currency) => new Intl.NumberFormat(`en-GB`, {
  currency,
  style: 'currency',
}).format(value)

const CharitiesPage = ({ data, location }) => {
  const { name, image, candles, content, links, support, fundraiser, giftACandleURL } = data.charitiesYaml

  const originCandle = new URLSearchParams(location.search || "").get("candle")?.replaceAll("_", "-")

  return (
    <Layout className={`min-h-full bg-slate-200 charity-page`}>
      <div className="container mx-auto max-w-xl">
        <header
          className={`bg-slate-50 rounded-b-3xl min-h-[50vh] pb-28 flex flex-col items-center justify-center relative`}
        >
          {originCandle && (
            <div className="absolute top-2 left-2">
              <a href={`/${originCandle}`} className="inline-flex flex-row gap-2 px-3 py-2 rounded-md drop-shadow-lg bg-slate-800 text-white">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>

                Back to my candle
              </a>
            </div>
          )}
          <img className="h-72 py-12" src={image.publicURL} />
          <h1 className="text-slate-800 font-bold text-4xl text-center">{name}</h1>
          <div className="flex flex-row gap-4 p-8">
            {Object.entries(links).map(([type, value]) => (
              <Social key={type} type={type} value={value} />
            ))}
          </div>
        </header>
        <section className="sm:px-0 drop-shadow-xl bg-white relative -mt-28 left-0 right-0 mx-2 sm:mx-4 rounded-3xl bg-white sticky -top-56">
          <div className="p-4 pb-0 w-full">
            <div className="flex flex-col justify-center items-center h-36 gap-4">
              <div className="text-lg font-light text-slate-400 text-center">Your candle has helped raise</div>
              <div className="flex flex-row justify-center items-baseline">
                <strong className="text-6xl font-light text-slate-500">{money(fundraiser.raised, fundraiser.currency)}</strong>
                <strong className="text-xl font-light text-slate-300">/{money(fundraiser.target, fundraiser.currency)}</strong>
              </div>
            </div>
            <div className="rounded-full w-full h-3 bg-slate-200">
              <div style={{ width: `${Math.max(fundraiser.raised/fundraiser.target*100, 1)}%` }} className="rounded-full h-3 bg-green-400"></div>
            </div>
            <div>
              <p className="text-center my-3 text-sm text-slate-300">Spread the love</p>
              <div className="pb-1">
                <div className="pb-3 bg-white">
                  <a
                    href={giftACandleURL}
                    onClick={() => window.fathom?.trackGoal("PKJVQYZA", 0)}
                    className="bg-green-400 text-green-900 drop-shadow-md rounded-xl flex flex-row p-4 text-center uppercase font-medium text-md justify-center items-center">
                    <div><GiftIcon className="h-4 w-4" /></div>
                    <div className="mx-auto">Gift a candle</div>
                  </a>
                  <div className="flex flex-row items-center gap-2 pt-4 justify-end">
                    <p className="text-center mb-1 text-sm text-slate-300">Or support them directly</p>
                    <div className="flex flex-row gap-4 px-2 justify-center items-center">
                      {Object.entries(support).map(([type, value]) => (
                        <Social key={type} type={type} value={value} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="p-4 px-6 text-gray-700 text-lg">
            <ReactMarkdown>
              {content}
            </ReactMarkdown>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default CharitiesPage

export const Head: HeadFC = ({ data }) => {
  const { name } = data.charitiesYaml

  return (
    <>
      <title>{name} - Supported by NoBees Candles</title>
    </>
  )
}

export const query = graphql`
  query($id: String) {
    charitiesYaml(id: { eq: $id }) {
      name
      content
      giftACandleURL
      fundraiser {
        raised
        target
        currency
      }
      links {
        website
        instagram
        facebook
      }
      support {
        patreon
        paypal
      }
      image {
        publicURL
      }
    }
  }
`
